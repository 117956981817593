import React, { Suspense, lazy } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Loading from '../Components/Loading/Loading';

const TopHeader = lazy(() => import('./TopHeader'));
const Footer = lazy(() => import('./Footer'));
const Home = lazy(() => import('../ContentSections/Home/Home'));


class MainLayout extends React.Component {

    render() {

        return (
            <BrowserRouter>
                <Suspense fallback={<div className="d-flex w-100 justify-content-center align-items-center text-center  p-1"><div className="d-flex ImageSize2"><Loading /></div></div>}>
                    <div>
                        <Suspense fallback={<div className="d-flex w-100 justify-content-center align-items-center text-center  p-1"><div className="d-flex ImageSize2"><Loading /></div></div>}>
                            <TopHeader />
                        </Suspense>
                        <Switch>
                            <Suspense fallback={<div className="d-flex w-100 justify-content-center align-items-center text-center  p-1"><div className="d-flex ImageSize2"><Loading /></div></div>}>
                                <Route path="/*" render={(props) => (<Home />)} />
                            </Suspense>
                        </Switch>
                        <Suspense fallback={<div className="d-flex w-100 justify-content-center align-items-center text-center  p-1"><div className="d-flex ImageSize2"><Loading /></div></div>}>
                            <Footer />
                        </Suspense>
                    </div>

                </Suspense>
            </BrowserRouter>
        );
    }

}

export default MainLayout;