import React from 'react';

class Loading extends React.Component {
    render() {
        return (
            <div>
                Loading Image and Text for each component Shoud Go Here!!!
            </div>
        )
    }
}
export default Loading